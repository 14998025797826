import request from '@/utils/request'


// 查询直播签到设置列表
export function listLiveSign(query) {
  return request({
    url: '/liveSign/liveSign/list',
    method: 'get',
    params: query
  })
}

// 查询直播签到设置分页
export function pageLiveSign(query) {
  return request({
    url: '/liveSign/liveSign/page',
    method: 'get',
    params: query
  })
}

// 查询直播签到设置详细
export function getLiveSign(data) {
  return request({
    url: '/liveSign/liveSign/detail',
    method: 'get',
    params: data
  })
}

// 新增直播签到设置
export function addLiveSign(data) {
  return request({
    url: '/liveSign/liveSign/add',
    method: 'post',
    data: data
  })
}

// 修改直播签到设置
export function updateLiveSign(data) {
  return request({
    url: '/liveSign/liveSign/edit',
    method: 'post',
    data: data
  })
}

// 删除直播签到设置
export function delLiveSign(data) {
  return request({
    url: '/liveSign/liveSign/delete',
    method: 'post',
    data: data
  })
}
