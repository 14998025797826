<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <!--<a-form-model-item prop="liveId" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播签到设置.直播id')}}<template slot="title">{{$t('直播签到设置.直播id')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.liveId"  :placeholder="$t('通用.输入.请输入')+$t('直播签到设置.直播id')" />-->
      <!--</a-form-model-item>-->
      <a-form-model-item prop="title" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播签到设置.签到标题')}}<template slot="title">{{$t('直播签到设置.签到标题')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.title" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('直播签到设置.签到标题')" />
      </a-form-model-item>
      <a-form-model-item prop="startDate" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播签到设置.签到开始时间')}}<template slot="title">{{$t('直播签到设置.签到开始时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.startDate" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
            <a-form-model-item prop="endTime" >
              <span slot="label" >
                <a-tooltip>
                  {{$t('直播签到设置.签到结束倒计时')}}<template slot="title">{{$t('直播签到设置.签到结束倒计时')}}</template>
                </a-tooltip>
              </span>
              <a-input-number style="width: 100%" :min="10" v-model="form.endTime"  show-time allow-clear/>
            </a-form-model-item>
<!--      <a-form-model-item prop="endDate" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播签到设置.签到结束时间')}}<template slot="title">{{$t('直播签到设置.签到结束时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.endDate" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop="tip" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播签到设置.签到提示')}}<template slot="title">{{$t('直播签到设置.签到提示')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.tip" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('直播签到设置.签到提示')" />
      </a-form-model-item>
      <a-form-model-item prop="buttonName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播签到设置.签到按钮文字')}}<template slot="title">{{$t('直播签到设置.签到按钮文字')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.buttonName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('直播签到设置.签到按钮文字')" />
      </a-form-model-item>
      <a-form-model-item prop="endTip" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播签到设置.结束提示')}}<template slot="title">{{$t('直播签到设置.结束提示')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.endTip" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('直播签到设置.结束提示')" />
      </a-form-model-item>

      <!--<a-form-model-item prop="signScore" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播签到设置.签到赠送积分')}}<template slot="title">{{$t('直播签到设置.签到赠送积分')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.signScore"  @change="limitPrice()" :placeholder="$t('通用.输入.请输入')+$t('直播签到设置.签到赠送积分')" />-->
      <!--</a-form-model-item>-->




      <!--<a-form-model-item prop="colUserInfo" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('是否采集用户信息')}}<template slot="title">{{$t('是否采集用户信息')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-select :placeholder="$t('通用.输入.请选择')+$t('是否采集手机号')" v-model="form.colUserInfo" allowClear>-->
          <!--<a-select-option  :value="0">{{$t('否')}}</a-select-option>-->
          <!--<a-select-option  :value="1">{{$t('是')}}</a-select-option>-->
        <!--</a-select>-->
      <!--</a-form-model-item>-->


      <!--<a-form-model-item prop="colMobile" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('是否采集手机号')}}<template slot="title">{{$t('是否采集手机号')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-select :placeholder="$t('通用.输入.请选择')+$t('是否采集手机号')" v-model="form.colMobile" allowClear>-->
          <!--<a-select-option  :value="0">{{$t('否')}}</a-select-option>-->
          <!--<a-select-option  :value="1">{{$t('是')}}</a-select-option>-->
        <!--</a-select>-->
      <!--</a-form-model-item>-->

      <!--<a-form-model-item prop="colLanguage" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('签到语设置')}}<template slot="title">{{$t('签到语设置')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-select :placeholder="$t('通用.输入.请选择')+$t('是否采集手机号')" v-model="form.colLanguage" allowClear>-->
          <!--<a-select-option  :value="0">{{$t('否')}}</a-select-option>-->
          <!--<a-select-option  :value="1">{{$t('是')}}</a-select-option>-->
        <!--</a-select>-->
      <!--</a-form-model-item>-->

      <!--<a-form-model-item prop="forceSign" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('强制签到')}}<template slot="title">{{$t('签到语设置')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-select :placeholder="$t('通用.输入.请选择')+$t('强制签到')" v-model="form.forceSign" allowClear>-->
          <!--<a-select-option  :value="0">{{$t('否')}}</a-select-option>-->
          <!--<a-select-option  :value="1">{{$t('是')}}</a-select-option>-->
        <!--</a-select>-->
      <!--</a-form-model-item>-->


      <!--<a-form-model-item prop="signStyle" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('签到样式')}}<template slot="title">{{$t('签到样式')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-select :placeholder="$t('通用.输入.请选择')+$t('强制签到')" v-model="form.signStyle" allowClear>-->
          <!--<a-select-option  :value="10">{{$t('样式一')}}</a-select-option>-->
          <!--<a-select-option  :value="20">{{$t('样式二')}}</a-select-option>-->
        <!--</a-select>-->
      <!--</a-form-model-item>-->


      <!--<a-form-model-item prop="remark" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播签到设置.备注')}}<template slot="title">{{$t('直播签到设置.备注')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
      <!--</a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
  import { getLiveSign, addLiveSign, updateLiveSign } from '@/api/liveSign/liveSign'
  import {mapGetters} from 'vuex'
  import CustomDictTag from "@/components/DictCustomTag";
  import {generateFilePath, uploadObject} from "@/api/tool/oss";


  export default {
    name: 'CreateForm',
    props: {
    },
    components: {
      CustomDictTag,
    },
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        submitLoading: false,
        formTitle: '',
        previewImageVisible: false,
        previewUrl: '',
        previewVideoVisible: false,
        loading: '',
        // 表单参数
        form: {
          id: null,

          liveId: null,

          title: null,

          startDate: null,

          endDate: null,
          endTime: null,

          tip: null,

          buttonName: null,

          endTip: null,

          signScore: null,

          colUserInfo: null,

          colMobile: null,

          colLanguage: null,

          forceSign: null,

          signStyle: null,

          createTime: null,

          remark: null,

        },
        // 1增加,2修改
        formType: 1,
        open: false,
        rules: {
          liveId: [
            { required: true, message: this.$t('直播签到设置.直播id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ],
          title: [
            { required: true, message: this.$t('直播签到设置.签到标题')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ],
          startDate: [
            { required: true, message: this.$t('直播签到设置.签到开始时间')+this.$t('通用.文本.不能为空'), trigger: 'change' }
          ],
          endDate: [
            { required: true, message: this.$t('直播签到设置.签到结束时间')+this.$t('通用.文本.不能为空'), trigger: 'change' }
          ],
          endTime: [
            { required: true, message: this.$t('直播签到设置.签到结束倒计时')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ],
          tip: [
            { required: true, message: this.$t('直播签到设置.签到提示')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ],
          buttonName: [
            { required: true, message: this.$t('直播签到设置.签到按钮文字')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ],
          buttonName: [
            { required: true, message: this.$t('直播签到设置.签到按钮文字')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ],
          endTip: [
            { required: true, message: this.$t('直播签到设置.结束提示')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ],
          signScore: [
            { required: true, message: this.$t('直播签到设置.签到积分')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
          ]

        }
      }
    },
    filters: {
    },
    created () {
    },
    computed: {
      ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        liveId: null,
        title: null,
        startDate: null,
        endDate: null,
        endTime: null,
        tip: null,
        buttonName: null,
        endTip: null,
        signScore: null,
        colUserInfo: null,
        colMobile: null,
        colLanguage: null,
        forceSign: null,
        signStyle: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLiveSign({"id":id}).then(response => {
        this.form = response.data
      this.open = true
      this.formTitle = this.$t('通用.文本.修改')
    })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLiveSign(this.form).then(response => {
              this.$message.success(
              message,
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
              this.submitLoading = false
          })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLiveSign(this.form).then(response => {
              this.$message.success(
              message,
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
              this.submitLoading = false
          })
          }
        } else {
          return false
        }
      })
    },


    /** 查询直播数据统计 */
    handleLiveData (row) {
      //this.reset()
      //this.formType = 1
      this.open = true
      this.formTitle = this.$t('新增直播签到')
      this.form.liveId = row.id;

    },
    limitPrice() {
      // console.log(i);
      let val = this.form.signScore.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.signScore = val
    },


  }
  }
</script>
