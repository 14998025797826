<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <div class="live-info-container">
        <a-card :bordered="false" 
                :title="$t('直播信息')"
                :headStyle="{'background-color':'#f3f3f3','height': '40px','border-bottom': '1px solid #DDD'}"
                :bodyStyle="{'background-color':'#fbfbfb', 'padding': '24px'}">
          <div class="info-list">
            <div class="info-item">
              <div class="info-label">{{$t('主题')}}：</div>
              <div class="info-content">{{shareInfo.theme}}</div>
            </div>

            <div class="info-item">
              <div class="info-label">{{$t('封面')}}：</div>
              <div class="info-content">
                <img :src="shareInfo.thumb" 
                     class="cover-img" 
                     @click="handleImagePreview(shareInfo.thumb)"/>
              </div>
            </div>

            <div class="info-item">
              <div class="info-label">{{$t('推流地址')}}：</div>
              <div class="info-content">{{shareInfo.pushUrl}}</div>
            </div>

            <div class="info-item">
              <div class="info-label">{{$t('拉流地址')}}：</div>
              <div class="info-content">{{shareInfo.pullUrl}}</div>
            </div>

            <div class="info-item">
              <div class="info-label">{{$t('分享地址')}}：</div>
              <div class="info-content">{{shareInfo.shareUrl}}</div>
            </div>

            <div class="info-item">
              <div class="info-label">{{$t('分享二维码地址')}}：</div>
              <div class="info-content">
                <div class="qrcode-wrapper" @click="handleQrcodePreview">
                  <vue-qr ref="qrcode"
                          :text="shareInfo.shareUrl"
                          :size="150"
                          :margin="0"
                          colorDark="#333"
                          backgroundColor="#eee"
                          :logoScale="0.21"
                          @generate="onQRGenerated">
                  </vue-qr>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </div>

      <div class="bottom-control">
        <a-space>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>

      <radio-goods-select-form ref="RadioGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
      <q-r-code-vue ref="QRCodeVue"></q-r-code-vue>

    </a-form-model>

    <a-modal :visible="previewVisible" 
             :footer="null" 
             @cancel="handlePreviewCancel">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>

  </a-drawer>
</template>

<script>
import { getLive, addLive, updateLive } from '@/api/live/live'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import vueQr from 'vue-qr'
//选择商品
import RadioGoodsSelectForm from "@/views/zb/seckill/modules/RadioSelectFormGoods";
import {listCategory} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";
//选择商品

export default {
  name: 'CreateFormShare',
  props: {
  },
  components: {
    CustomDictTag,
    //选择商品
    RadioGoodsSelectForm,
    //选择商品
    vueQr
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',

      shareParam:{
        id: null,
        type: "liveShareUrl",
        videoType: ""
      },
      shareInfo:{

      },

      //选择商品
      categoryList:[],
      goodsColumns: [
        /*{
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },*/
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 180,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
      ],
      //选择商品

      // 表单参数
      form: {
        //选择商品
        goodsList: [],
        //选择商品

        id: null,

        imGroupId: null,

        userId: null,

        shopId: null,

        shopName: null,

        shopLogo: null,

        thumb: null,

        theme: null,

        intro: null,

        liveName: null,

        openTime: null,

        closeTime: null,

        expiredTime: null,

        effective: null,

        duration: null,

        pushUrl: null,

        pullUrl: null,

        chatNum: null,

        totalMoney: null,

        status: 0,

        cutInfo: null,

        isErrorClose: null,

        errorCloseTime: null,

        classifyId: null,

        liveType: null,

        liveAddress: null,

        liveCityName: null,

        redPacketExpiredTime: null,

        redPacketBeginTime: null,

        createTime: null,

        remark: null,
        signInter:null,
        signScore:null,
        seekInter:null,
        seekScore:null,
        userShare:null,
        signShowTime:null,
        signNum:null,
        liveScreen:null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        imGroupId: [
          { required: true, message: this.$t('腾讯IM群ID(发起直播单独创建一个群)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        theme: [
          { required: true, message: this.$t('直播主题')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isErrorClose: [
          { required: true, message: this.$t('是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        liveScreen:[
          { required: true, message: this.$t('直播主题')+this.$t('通用.输入.请选择'), trigger: 'blur' }
        ]
      },
      previewVisible: false,
      previewImage: '',
      qrCodeImage: '', // 用于存储生成的二维码图片
    }
  },
  filters: {
  },
  created () {
    //选择商品
    this.getCateList();
    this.getBrandList();
    //选择商品
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        //选择商品
        goodsList: [],
        //选择商品
        id: null,
        imGroupId: null,
        userId: null,
        shopId: null,
        shopName: null,
        shopLogo: null,
        thumb: null,
        theme: null,
        intro: null,
        liveName: null,
        openTime: null,
        closeTime: null,
        expiredTime: null,
        effective: null,
        duration: null,
        pushUrl: null,
        pullUrl: null,
        chatNum: null,
        totalMoney: null,
        status: 0,
        cutInfo: null,
        isErrorClose: null,
        errorCloseTime: null,
        classifyId: null,
        liveType: null,
        liveAddress: null,
        liveCityName: null,
        redPacketExpiredTime: null,
        redPacketBeginTime: null,
        createTime: null,
        remark: null,
        remark: null,
        signInter:null,
        signScore:null,
        seekInter:null,
        seekScore:null,
        userShare:null,
        signShowTime:null,
        signNum:null,
      }

    },

    /** 新增按钮操作 */
    handleShare (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('分享')
      getLive({"id":row.id}).then(response => {
        this.shareInfo = response.data
        this.creatQrCode();
      })
    },


    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLive({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    limitPrice() {
      // console.log(i);
      let val = this.form.signScore.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.signScore = val
    },
    limitPrice1() {
      // console.log(i);
      let val = this.form.seekScore.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.seekScore = val
    },

    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'thumb'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'thumb', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    coverDetailImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'live'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'detailImg', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束

    //选择商品
    indexGoodsModalSelect(records) {
      this.form.goodsId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },
    //选择商品



    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },
    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    //选择商品
    handleImagePreview(imageUrl) {
      this.previewImage = imageUrl;
      this.previewVisible = true;
    },
    handlePreviewCancel() {
      this.previewVisible = false;
    },
    handleQrcodePreview() {
      if (this.qrCodeImage) {
        this.previewImage = this.qrCodeImage;
        this.previewVisible = true;
      }
    },
    onQRGenerated(dataUrl) {
      // 二维码生成完成后的回调
      this.qrCodeImage = dataUrl;
    },
  }
}
</script>

<style lang="less" scoped>
.live-info-container {
  padding: 0 24px;
}

.info-list {
  .info-item {
    display: flex;
    margin-bottom: 24px;
    
    &:last-child {
      margin-bottom: 0;
    }

    .info-label {
      width: 120px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 32px;
      font-size: 14px;
    }

    .info-content {
      flex: 1;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.65);
      word-break: break-all;
    }
  }
}

.cover-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    opacity: 0.8;
  }
}

.bottom-control {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.qrcode-wrapper {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    opacity: 0.8;
  }
  
  /deep/ canvas {
    border-radius: 4px;
  }
}
</style>