<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">


      <div style="margin-top: 50px;">
        <a-card  :bordered="false" :title="$t('签到列表')"
                 :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                 :bodyStyle="{'background-color':'#fbfbfb'}">

          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            :columns="tableColumns"
            :data-source="tableList"
            :pagination="false"
            :bordered="tableBordered"
          >

            <template slot="avatar" slot-scope="text, record">
              <div>
                <img v-if="record.avatar" :src="record.avatar"  style="width:60px;height:62px;" @click="handleRecordPreview(record.avatar)" alt="none"/>
              </div>
            </template>

            <!--:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
            <!--@change="handleSortChange"-->
          </a-table>

          <a-pagination
            :scroll="{ x: '130%' }"
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :pageSizeOptions="pageSizeOptions"
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="total => getTotal(total)"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />

        </a-card>
      </div>









      <div class="bottom-control">
        <a-space>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>

    </a-form-model>
  </a-drawer>
</template>

<script>
  import { getLive, addLive, updateLive,getLiveData,pageLive,getMembersList } from '@/api/live/live'
  import {mapGetters} from 'vuex'
  import CustomDictTag from "@/components/DictCustomTag";
  import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
  import { pageLiveSign,listLiveSign, delLiveSign } from '@/api/liveSign/liveSign'

  export default {
    name: 'CreateFormData',
    props: {
    },
    components: {
      CustomDictTag,
    },
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        submitLoading: false,
        formTitle: '',
        previewImageVisible: false,
        previewUrl: '',
        previewVideoVisible: false,
        liveId:null,

        liveData:{

        },

        // 1增加,2修改
        formType: 1,
        open: false,
        loading: false,
        tableColumns: [
          // {
          //   title: this.$t('直播签到设置.id'),
          //   dataIndex: 'id',
          //   ellipsis: true,
          //   align: 'center'
          // },
          // {
          //   title: this.$t('直播签到设置.直播id'),
          //   dataIndex: 'liveId',
          //   ellipsis: true,
          //   align: 'center'
          // },
          {
            title: this.$t('直播签到设置.签到标题'),
            dataIndex: 'title',
            ellipsis: true,
            align: 'center'
          },
          {
            title: this.$t('直播签到设置.签到开始时间'),
            dataIndex: 'startDate',
            scopedSlots: { customRender: 'startDate' },
            ellipsis: true,
            align: 'center'
          },
          // {
          //   title: this.$t('直播签到设置.签到结束时间'),
          //   dataIndex: 'endDate',
          //   scopedSlots: { customRender: 'endDate' },
          //   ellipsis: true,
          //   align: 'center'
          // },
          {
            title: this.$t('直播签到设置.签到结束倒计时'),
            dataIndex: 'endTime',
            scopedSlots: { customRender: 'endTime' },
            ellipsis: true,
            align: 'center'
          },
          {
            title: this.$t('直播签到设置.签到提示'),
            dataIndex: 'tip',
            ellipsis: true,
            align: 'center'
          },
          {
            title: this.$t('直播签到设置.签到按钮文字'),
            dataIndex: 'buttonName',
            ellipsis: true,
            align: 'center'
          },
          {
            title: this.$t('直播签到设置.结束提示'),
            dataIndex: 'endTip',
            ellipsis: true,
            align: 'center'
          },
          {
            title: this.$t('直播签到设置.签到赠送积分'),
            dataIndex: 'signScore',
            ellipsis: true,
            align: 'center'
          },
          {
            title: this.$t('是否采集用户信息'),
            dataIndex: 'colUserInfo',
            ellipsis: true,
            align: 'center',
            customRender: function (v) {
              if (v == 1) {
                return "是";
              }else {
                return '否';
              }
            }
          },
          {
            title: this.$t('是否采集手机号'),
            dataIndex: 'colMobile',
            ellipsis: true,
            align: 'center',
            customRender: function (v) {
              if (v == 1) {
                return "是";
              }else {
                return '否';
              }
            }
          },
          {
            title: this.$t('签到语设置'),
            dataIndex: 'colLanguage',
            ellipsis: true,
            align: 'center',
            customRender: function (v) {
              if (v == 1) {
                return "是";
              }else {
                return '否';
              }
            }
          },
          {
            title: this.$t('强制签到'),
            dataIndex: 'forceSign',
            ellipsis: true,
            align: 'center',
            customRender: function (v) {
              if (v == 1) {
                return "是";
              }else {
                return '否';
              }
            }
          },
          {
            title: this.$t('签到样式'),
            dataIndex: 'signStyle',
            ellipsis: true,
            align: 'center',
            customRender: function (v) {
              if (v == 10) {
                return "样式一";
              }else if (v == 20){
                return '样式二';
              }
            }
          },
          {
            title: this.$t('是否推送'),
            dataIndex: 'pushFlag',
            ellipsis: true,
            align: 'center',
            customRender: function (v) {
              if (v == 1) {
                return "是";
              }else {
                return '否';
              }
            }
          },
          // {
          //   title: this.$t('直播签到设置.创建时间'),
          //   dataIndex: 'createTime',
          //   scopedSlots: { customRender: 'createTime' },
          //   ellipsis: true,
          //   align: 'center'
          // },
          // {
          //   title: this.$t('直播签到设置.备注'),
          //   dataIndex: 'remark',
          //   ellipsis: true,
          //   align: 'center'
          // },
          // {
          //   title: this.$t('通用.文本.操作'),
          //   dataIndex: 'operation',
          //   width: '18%',
          //   scopedSlots: { customRender: 'operation' },
          //   align: 'center'
          // }
        ],
        tableList:[],
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
        queryParam: {
          liveId: undefined,
          nickName: undefined,
          pageNum: 1,
          pageSize: 10
        },

      }
    },
    filters: {
    },
    created () {

    },
    computed: {
      ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },

    // 表单重置
    reset () {

    },

    /** 查询直播签到数据统计 */
    handleLiveSignData (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('签到列表')
      this.queryParam.liveId = row.id;
      this.getList();
    },



    /** 查询直播签到设置列表 */
    getList () {
      this.loading = true
      pageLiveSign(this.queryParam).then(response => {
        this.tableList = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },


    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },





  }
  }
</script>
<style lang="less" scoped>
  .title {
    color: rgba(0, 0, 0, .85);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .message-text {
    font-family: MicrosoftYaHei;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3rem;
    letter-spacing: 0rem;
    color: #333333;
    width: 50%;
  }

  .fontblack {
    color: #999999
  }

  .img2 {
    width: .81rem;
    height: .8rem;
    float: right;
  }


  .orderTitle {
    font-size: 1rem;
    color: #333333;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    height: 2.5rem;
  }

  .orderDetail {
    font-size: 0.8rem;
    color: #666666;
    text-align: left;
  }

  .border-ceter {
    width: 92%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .pay-button {
    width: 88%;
    height: 2.6rem;
    position: relative;
    background-color: red;
    color: white;

    margin-left: 6%;
  }

  ul li {
    list-style: none;
    font-size: 1rem;
  }

  ul {
    padding-left: 1.5rem
  }

  .track-rcol {
  }

  .track-list {
    position: relative;
  }

  .track-list li {
    position: relative;
    padding: 0px 0 1.5rem 25px;
    line-height: 1rem;
    border-left: 1px solid #d9d9d9;
    color: #999;
  }

  .track-list li.first {
    color: red;
    padding-top: 0;
    width: 100%;
    text-align: left;
    border-left: 1px solid #d9d9d9;
  }

  .track-list li .node-icon {
    position: absolute;
    left: -5.5px;
    border-radius: 0.25rem;
    width: 0.5rem;
    height: 0.5rem;
    top: 4px;
    background-color: #999999;
  }

  .track-list li.first .node-icon {
    background-position: 0-72px;
    background-color: red;
    width: 1rem;
    z-index: 2;
    height: 1rem;
    position: absolute;
    left: -9px;
    top: 0;
    border-radius: 0.5rem;
  }

  .track-list li .time {
    margin-right: 20px;
    position: relative;
    top: 4px;
    display: inline-block;
    vertical-align: middle;
    background-color: white;
    color: #999;
    width: 100%;
    text-align: left;
  }

  .track-list li .txt {
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: #999;
  }

  .track-list li.first .time {
    text-align: left;
    width: 94%;
    color: red;
  }

  .track-list li.first .txt {
    color: red;
    text-align: left;
    width: 94%;
  }

  .track-list li.finall {
    position: relative;
    padding: 0px 0 1.5rem 25px;
    line-height: 18px;
    border-color: white;
    border-left: 1px solid #ffffff;
    color: #999;
  }

  .track-list li.finall .div-spilander {
    width: 1px;
    position: absolute;
    position: absolute;
    left: -1.5px;
    height: 0.5rem;
    background-color: #d9d9d9;
  }

  .order-info {
    border: 1px solid #e8e8e8;
    margin-top: 40px;

    .info-table {
      tr {
        height: 50px;
      }
      td {
        display: table-cell;
        vertical-align: top;
        font-size: 16px;
      }

      .first {
        width: 100px;
      }

      .data {
        width: 300px;
      }
    }
  }

  .detailPanel {
    dd, dt {
      display: inline-block;
      vertical-align: center;
    }

    dt img {
      margin: 0 20px 5px 0px;
    }

    dd {
      h3 {
        font-weight: bold;
      }
    }

    .trade-detail-prompt {
      margin-left: 35px;

      li {
        font-size: 13px !important;

        .dotted-node {
          padding: 0 5px;
        }
      }
    }
  }

  .order-money {
    .om-c1 {
      font-size: 14px;
      color: #666
    }

    .om-c2 {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .om-c3 {
      font-size: 16px;
      color: #DE3636;
      font-weight: bold;
    }

    td {
      text-align: right;
    }

    float: right;
    margin: 20px 40px 20px 0;
  }
</style>
